import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

import { AutocompleteSelectOption } from '$features/devices/autocomplete';

countries.registerLocale(enLocale);

export const countryOptions: AutocompleteSelectOption[] = Object.entries(
  countries.getNames('en', { select: 'official' }),
)
  .map(([key, val]): AutocompleteSelectOption => ({ label: val, value: key }))
  .sort((optionA: AutocompleteSelectOption, optionB: AutocompleteSelectOption) =>
    optionA.label.localeCompare(optionB.label),
  );

export const getCountryName = (countrySelected: string) =>
  countryOptions.find((country) => country.value === countrySelected);

// This method prevents that the previous values gets broken.
export const isValidISOCountry = (countryISO: string) =>
  countryISO.length === 2 && countries.isValid(countryISO);

import {
  Device,
  Pagination as PaginiationType,
} from '@eppendorf/vnls-inventory-service-types';
import {
  Badge,
  Button,
  Icon,
  IconSizeClasses,
  SortableTable,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { AccessControlGuard } from '$shared/access-control/access-control-guard';
import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Overview } from '$components/overview-box/overview-box';
import { Pagination } from '$components/table/pagination/pagination';
import { DeviceDialogWrapper } from '$features/add-device';
import { DeviceNameOrSerialNumber } from '$features/device-list/device-name-or-serialnumber';
import { DeviceTypeCell } from '$features/device-list/device-type-cell';
import {
  useAddedDevices,
  useDeleteDevice,
  useDevicesWithQueryParams,
} from '$features/devices/devices.api';
import { getConnectionStatusText } from '$features/devices/devices.utils';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';

function useDeleteDeviceHandler() {
  const { mutate } = useDeleteDevice();
  const deleteDeviceHandler = useCallback(
    async (device: Device) => {
      mutate(device);
    },
    [mutate],
  );
  return deleteDeviceHandler;
}

export function DeviceList(): ReactElement {
  const navigate = useNavigate();
  const routeError = useRouteError();
  const hasActionsPermission = useAccessControlGuard('DEREGISTER_DEVICE');
  const isPermissionsFeatureEnabled = isFeatureEnabled(
    'applyPermissionsToDeviceOverviewPage',
  );
  const isDeregisterDeviceFeatureEnabled = isFeatureEnabled('deregisterDevice');
  const [{ page, pageSize }, setPagination] = useState<PaginiationType>({
    page: 1,
    pageSize: 10,
  });

  const [isDeviceDialogOpen, setIsDeviceDialogOpen] = useState(false);

  const { t } = useTranslation();

  const {
    data: paginatedData,
    isError,
    isLoading,
    refetch,
  } = useDevicesWithQueryParams({ page, pageSize });

  const { data: addQueryState } = useAddedDevices();

  useDidMountEffect(() => {
    refetch();
  }, [page, pageSize]);

  const devices = paginatedData ? paginatedData.data : [];

  useEffect(() => {
    if (addQueryState && addQueryState.length > 0) {
      setPagination({ pageSize, page: 1 });
    }
  }, [addQueryState]);

  function handleRowClick(device: Device) {
    navigate({
      search: `?sidecarRoute=device/details&manufacturer=${device.manufacturer}&serialNumber=${device.serialNumber}`,
    });
  }

  const handlePageSizeChange = (newPageSize: string) => {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ pageSize, page: newPage });
  };

  const handleDeleteDevice = useDeleteDeviceHandler();

  const getBadgeForConnStatus = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components, func-names -- accepted
      function (connectionStatus: Device['connectionStatus']) {
        switch (connectionStatus) {
          case 'pending':
          case 'readyToPair':
            return (
              <Badge color="orange">{getConnectionStatusText(connectionStatus)}</Badge>
            );
          case 'paired':
            return (
              <Badge color="green">{getConnectionStatusText(connectionStatus)}</Badge>
            );
          case 'waitingForMetadata':
            return (
              <Badge color="orange">{getConnectionStatusText(connectionStatus)}</Badge>
            );
          default:
            return '';
        }
      },
    [paginatedData],
  );

  const columnHelper = createColumnHelper<Device>();
  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('connectionStatus', {
        header: () => t('shared.status'),
        cell: (info) => getBadgeForConnStatus(info.getValue()),
      }),
      columnHelper.accessor('type', {
        header: () => t('deviceTable.type'),
        // eslint-disable-next-line react/no-unstable-nested-components -- should be fine
        cell: (info) => <DeviceTypeCell device={info.row.original} />,
      }),
      columnHelper.accessor('name', {
        header: () => t('deviceTable.name'),
        // eslint-disable-next-line react/no-unstable-nested-components -- should be fine
        cell: (info) => <DeviceNameOrSerialNumber device={info.row.original} />,
      }),
      columnHelper.accessor('serialNumber', {
        header: () => t('devices.serialNumber'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('manufacturer', {
        header: () => t('deviceTable.manufacturer'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('model', {
        header: () => t('deviceTable.model'),
        cell: (info) => info.renderValue(),
      }),
      // using same accessor means you have to manually define unique ids (if no ids set, then
      // the accessor name is used for id and this results in errors if using same accessore multiple times)
      columnHelper.accessor('location', {
        header: () => t('deviceDetail.deviceInformation.building'),
        cell: (info) => info.getValue()?.building,
        id: 'building',
      }),
      columnHelper.accessor('location', {
        header: () => t('deviceDetail.deviceInformation.room'),
        cell: (info) => info.getValue()?.room,
        id: 'room',
      }),
    ];

    const actionColumn = columnHelper.display({
      id: 'actions',
      header: () => t('shared.actions'),
      // eslint-disable-next-line react/no-unstable-nested-components -- accepted
      cell: ({ row }) => (
        // The problem: When rendering the Dialog multiple times for each row,
        // the Dialog re-renders (=closes itself) when the row is re-rendered
        // (e.g. when /devices are being refetched)
        // The solution: We render the Dialog only once, and control it via state.
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteDevice(row.original);
          }}
          variant="icon"
        >
          <Icon name="delete" size={IconSizeClasses.XSmall} />
        </Button>
      ),
    });

    if (isDeregisterDeviceFeatureEnabled && hasActionsPermission) {
      return [...cols, actionColumn];
    }
    return cols;
  }, [isDeregisterDeviceFeatureEnabled, hasActionsPermission]);

  const [searchParams] = useSearchParams();

  return (
    <Overview>
      <DeviceDialogWrapper
        isOpen={isDeviceDialogOpen}
        onOpenChange={(open) => setIsDeviceDialogOpen(open)}
      />

      <Overview.Header>
        <h1 className="title">{t('devices.overview')}</h1>

        {isPermissionsFeatureEnabled ? (
          <AccessControlGuard requiredPermissions="ADD_DEVICE">
            <Button
              variant="primary"
              className="p-left-l"
              onClick={() => setIsDeviceDialogOpen(true)}
            >
              <Icon
                name="plus"
                size={IconSizeClasses.XSmall}
                className="bg-white m-right-s"
              />

              {t('addDevice.add')}
            </Button>
          </AccessControlGuard>
        ) : (
          <Button
            variant="primary"
            className="p-left-l"
            onClick={() => setIsDeviceDialogOpen(true)}
          >
            <Icon
              name="plus"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-s"
            />

            {t('addDevice.add')}
          </Button>
        )}
      </Overview.Header>
      <Overview.Body>
        {(routeError || isError) && <LoadingErrorHint />}

        {!isLoading && !isError && (
          <>
            {devices && !isLoading && paginatedData?.pagination && (
              <>
                <SortableTable<Device[], Device>
                  data={devices}
                  columns={columns}
                  onRowClick={(device) => handleRowClick(device)}
                  isSelectedRow={(device) =>
                    isValueWithKeyInSearchParams(
                      'serialNumber',
                      device.serialNumber,
                      searchParams,
                    )
                  }
                />
                <Pagination
                  paginationData={paginatedData.pagination}
                  onPageSizeChange={(size) => handlePageSizeChange(size)}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </>
            )}

            {!isLoading && !isError && (!devices || devices.length === 0) && (
              <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
                <p className="m-bottom-xxl">{t('deviceTable.emptyState')}</p>

                <Button size="small" onClick={() => setIsDeviceDialogOpen(true)}>
                  <Icon
                    name="plus"
                    className="bg-white m-right-xs"
                    size={IconSizeClasses.Small}
                  />
                  {t('addDevice.add')}
                </Button>
              </div>
            )}
          </>
        )}
      </Overview.Body>
    </Overview>
  );
}

import { Label } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import React, { FunctionComponentElement } from 'react';
import { OptionProps, components } from 'react-select';


import { AutocompleteSelectOption } from './autocomplete.definition';

export function SelectOptionComponent({
  ...props
}: OptionProps<AutocompleteSelectOption>): FunctionComponentElement<
  OptionProps<AutocompleteSelectOption>
> {

  return (
    <components.Option
      // eslint-disable-next-line react/jsx-props-no-spreading -- Need to maintain some cleanliness here
      {...props}
      className={cn(
        'flex flex__ai--center',
        {
          'multi-select__option--focused': props.isFocused,
          'multi-select__option--selected': props.isSelected,
        },
      )}
    >
      <Label htmlFor={`multi-select-option-${props.data.value}`}>{props.data.label}</Label>
    </components.Option>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { TabNavigationRoute, TabsNavigation } from '$components/tabs/tabs-navigation';

export function MonitoringDetailNavigation(): ReactElement {
  const { t } = useTranslation();

  const monitoringDetailSidecarRoutes: TabNavigationRoute[] = [
    {
      label: t('monitoringDetail.parameters'),
      path: 'parameters',
      icon: 'parameter',
    },
    {
      label: t('monitoringDetail.history-charts'),
      path: 'history-charts',
      icon: 'charts',
    },
    {
      label: t('monitoringDetail.events'),
      path: 'events',
      icon: 'events',
    },
  ];

  const exportTab: TabNavigationRoute = {
    label: t('monitoringDetail.export'),
    path: 'export',
    icon: 'save',
  };

  // When the feature flag won't be needed, move the value for the export tap to the list above and remove this condition
  const updatedMonitoringDetailSidecarRoutes = isFeatureEnabled('dataExport')
    ? [...monitoringDetailSidecarRoutes, exportTab]
    : monitoringDetailSidecarRoutes;

  return <TabsNavigation routes={updatedMonitoringDetailSidecarRoutes} />;
}

export const DEVICES_BASE_PATH = '/inventory/devices';

export const SENSE_DEVICES_BASE_PATH = '/sense';

export const createDevicePath = (manufacturer: string, serialNumber: string) =>
  `${DEVICES_BASE_PATH}/${manufacturer}/${serialNumber}`;

// Notifications

export const NOTIFICATION_RULES_BASE_PATH = '/notification/notification-rules';

export const createNotificationRulePath = (id: string) =>
  `${NOTIFICATION_RULES_BASE_PATH}/${id}`;

// Users

export const USERS_BASE_PATH = '/user/users';

export const createUserPath = (id: string) => `${USERS_BASE_PATH}/${id}`;

// Configuration

export const APP_CONFIG_BASE_PATH = '/application-configuration';

export const createAppConfigPath = (partial: string) =>
  `${APP_CONFIG_BASE_PATH}/${partial}`;

// Device Events

export const DEVICE_EVENTS_BASE_PATH = '/device-events/device-events';

export const createDeviceEventsPath = (partial: string) =>
  `${DEVICE_EVENTS_BASE_PATH}/${partial}`;

// Licenses

export const LICENSES_BASE_PATH = `/licenses`;

export const createLicensesPath = (filename: string = 'licenses.json') =>
  `${LICENSES_BASE_PATH}/${filename}`;

// Sense Monitors

export const SENSE_MONITORS_BASE_PATH = `/sense/monitors`;

export const createMonitorPath = (monitorId: string, partial: string) =>
  `${SENSE_MONITORS_BASE_PATH}/${encodeURI(monitorId)}/${partial}`;

// Monitoring

export const MONITORING_BASE_PATH = `/monitoring-service`;

// Other

export const LOCALHOST_BASE_URL = 'http://localhost:5173';

export const LOCALHOST_GRAPHQL_URL = 'http://localhost:5173';

export function formatQueryParamsObject(obj: Record<string, unknown>): string[][] {
  return Object.entries(obj).reduce((prev: string[][], next: [string, unknown]) => {
    const [key, value] = next;
    if (Array.isArray(value)) {
      const urlParam: string[][] = [];
      value.forEach((item) => urlParam.push([key, item]));

      return [...prev, ...urlParam];
    }
    return [...prev, [key, String(value)]];
  }, []);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is used for query params
export function objectToURLSearchParams(obj: Record<string, any>) {
  return new URLSearchParams(obj);
}

export const createPathWithQueryParams = (
  basePath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is used for query params
  queryParams: Record<string, any>,
) =>
  `${basePath}?${new URLSearchParams(formatQueryParamsObject(queryParams)).toString()}`;
